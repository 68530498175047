body {
    font-family: Arial, sans-serif;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f9fc;
    min-height: 100vh;
    padding: 50px;
  }
  
  h1 {
    color: #4a4a4a;
    margin-bottom: 20px;
  }
  
  form {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    padding: 20px;
    width: 100%;
  }
  
  label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  input,
  textarea {
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    padding: 8px;
    width: 100%;
  }
  
  input:focus,
  textarea:focus {
    border-color: #8eb3e3;
    outline: none;
  }
  
  textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  span {
    color: #e74c3c;
    display: block;
    font-size: 12px;
    margin-top: 5px;
  }
  
  button {
    background-color: #f7ca01;
    border: none;
    border-radius: 4px;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px;
    text-transform: uppercase;
    width: 100%;
  }
  
  button:hover {
    background-color: #f5aa01;
  }