.video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
}

.home-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mute-button,
.play-again-button {
    position: absolute;
    bottom: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    min-width: 80px;
    max-width: 120px;
}

.mute-button {
    right: 10px;
}

.play-again-button {
    right: 140px;
}