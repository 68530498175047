.App {
  text-align: center;
}

.navbar {
  background-color: #f7ca01;
  color: black;
  
}

.navbar-content {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  text-align: center;
}

.navbar-middle h1 {
  font-size: 3rem;
}

.header-pic {
  height: 15rem;
  width: auto;
  border-radius: 15px;
  
}

.navbar-brand,
.nav-link {
  font-weight: bold;
}


.nav-link-p {
  color: #000;
  
}

.navbar-nav {
  list-style-type: none;
  flex-direction: row;
}

.custom-navbar .custom-navbar-collapse {
  justify-content: flex-end;
}

.nav-icon {
  font-size: 3rem;
}

.expanded-nav-items {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  
}

.expanded-nav-items .nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.expanded-nav-items .nav-link svg {
  margin-right: 5px;
}

.navbar-toggler {
  background-color: black !important;
}

.navbar-toggler:hover {
  background-color: #5a5a5a !important;
}

.icon-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


/* Responsive styles */

@media (max-width: 767.98px) {
  
  .navbar-left .navbar-right {
    width: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .navbar-brand {
    padding: 0.5rem 0;
  }

  .navbar-left,
  .navbar-middle {
    justify-content: center;
    width: 100%;
  }
}

/* Homepage.css */
.self-love {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
}
.homepage {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.homepage__title {
  font-size: 2.5rem;
  color: #3a3a3a;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.homepage__subtitle {
  font-size: 1.5rem;
  color: #5a5a5a;
  margin-bottom: 2rem;
  text-align: center;
}

.homepage__name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #3a3a3a;
  margin-bottom: 1rem;
  text-align: center;
}

.homepage__qualifications {
  font-size: 1rem;
  color: #5a5a5a;
  margin-bottom: 2rem;
  white-space: pre-wrap;
  text-align: center;
}

.homepage__intro,
.homepage__content {
  font-size: 1rem;
  color: #5a5a5a;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.homepage__cta {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

/* Responsive styles */
@media (min-width: 768px) {
  .homepage__title {
    font-size: 3rem;
  }

  .homepage__subtitle {
    font-size: 2rem;
  }

  .homepage__name {
    font-size: 2rem;
  }
}

/* TestimonialSlider */

.testimonial-slider {
  font-family: Arial, sans-serif;
  margin: 2rem 0;
  border-radius: 15px;
}

.testimonial-slider__title {
  font-size: 2rem;
  color: #3a3a3a;
  text-align: center;
  margin-bottom: 1rem;
}

.testimonial-slider__item {
  padding: 1rem;
}

.testimonial-slider__content {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
}

.testimonial-slider__comment {
  font-size: 1rem;
  color: #5a5a5a;
  margin-bottom: 1rem;
}

.testimonial-slider__name {
  font-size: 1rem;
  color: #3a3a3a;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.testimonial-slider__rating span {
  display: inline-block;
  font-size: 1rem;
  color: #f1c40f;
}

/* FootDetox page styles */

.foot-detox-page {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.foot-detox__title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.foot-detox__subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #555;
}

.foot-detox__header {
  font-size: 1.4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #444;
}

.foot-detox__content {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}

.foot-detox__list {
  list-style: disc;
  margin-left: 1.5rem;
  font-size: 1rem;
  color: #666;
  
}

.foot-detox__cta {
  text-align: center;
  margin-top: 2rem;
}

/* About Page */
.about-page {
  text-align: center;
  margin: 2rem 0;
}

.about-page h2,
.about-page h3 {
  position: relative;
  margin-bottom: 1rem;
}

.about-page h2::after,
.about-page h3::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  height: 2px;
  background-color: #000;
}

.about-page ul {
  list-style: none;
  padding: 0;
}

.about-image {
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
  max-width: 15%;
  height: auto;
}

.about-image-right {
  float: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
  max-width: 15%;
  height: auto;
}

.scrable-img {
  max-width: 80%;
}

/* Order Page */
.order-here {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.order-here__content {
  text-align: left;
  width: 80%;
  max-width: 800px;
}

.order-here__image-list-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
}

.order-here__image {
  width: 100%;
  max-width: 250px;
  height: auto;
  margin-right: 1rem;
  padding-bottom: 15px;
}

.order-here__list {
  flex: 1;
  text-align: left;
}

.order-here__button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  background-color: #f7ca01;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  margin: 3%;
  cursor: pointer;
  transition: background-color 0.2s;
}

.order-here__button:hover {
  background-color: #ffe600;
  color: #3a3a3a;
}

/* Blog */
.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.blog__title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.blog__list {
  list-style-type: none;
  padding: 0;
}

.blog__list-item {
  margin-bottom: 0.5rem;
}

.blog__list-item-link {
  text-decoration: none;
  color: #0072bc;
  font-weight: bold;
}

.blog__list-item-link:hover {
  text-decoration: underline;
}

/* src/components/Footer/Footer.css */
.footer .row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  
  padding: .5rem 0;
  background-color: #282c34;
  color: #fff;
}

.footer-links,
.footer-contact {
  display: inline-block;
  vertical-align: top;
}

.footer h5 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.footer ul {
  padding: 0;
  list-style-type: none;
}

.footer ul li a {
  color: #fff;
  text-decoration: none;
}

.footer ul li a:hover {
  color: #ccc;
  text-decoration: underline;
}



.footer address {
  font-style: normal;
  line-height: 1.6;
}

.credit {
  font-size: 0.9rem;
  margin-top: 1rem;
  color: #aaa;
}

.credit a {
  color: #aaa;
  text-decoration: none;
}

.credit a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Media Queries for responsive design */
@media (max-width: 767.98px) {
  .footer {
    padding: 0rem 0;
    text-align: center;
  }

  .footer-links,
  .footer-contact {
    display: block;
    width: 100%;
  }

  .footer h5 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .footer ul {
    margin-bottom: 1rem;
  }
}
